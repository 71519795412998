import React, { useEffect, useState  } from 'react';
//import { graphql } from "gatsby"
import { Link } from "gatsby"

import Layout from "components/Layout";
import Seo from 'components/Seo';
import Address from 'components/form/Address';
//import Suggest from 'components/form/Suggest';
import { StaticImage } from "gatsby-plugin-image"



const Reg = ({location, data}) => {
  const [openMap, setopenMap] = useState(false);
  const [fromAddress, setFrom] = useState('');

  return (
    <Layout openModal={openMap}>
      <Seo
        title={'Digital Transit Solutions'}
        pathname={location.pathname}
      />
      {/*<section>
        <div  className="container">

        </div>
      </section>*/}

      <section>
        <div  className="container">
          {/*<h1>Digital Transit Solutions</h1>*/}
          <h1>اخبرنا عن طريقك</h1>

          <div className="comboAddress">
            {/*<Suggest />
            <Suggest />*/}
            <div className="connector" />
          </div>



          <Address title="depart" titleAr="مكان الصعود"/>

          <Address title="distint" titleAr="مكان النزول"/>


          <label htmlFor="firstname">
            الاسم
            <input type="text" id="firstname" name="firstname" placeholder2="الاسم" required />
          </label>

          <label htmlFor="lastname">
            الكنية
            <input type="text" id="lastname" name="lastname" placeholder2="الكنية" required />
          </label>

          <label htmlFor="mobile">
          رقم الجوال
            <input type="tel" id="mobile" name="mobile" required />
          </label>

          <label htmlFor="appt">
          موعد الذهاب
            <input type="time" id="appt" name="appt" min="06:00" max="20:00" required />
          </label>


            <label htmlFor="email">البريد الإلكتروني</label>
            <input type="email" id="email" name="email" placeholder="البريد الإلكتروني" required />
            <small>نحن ملتزمون بحفظ بياناتك.</small>


          <label htmlFor="fruit">نوع الحافلة</label>
          <select id="fruit" required>
            <option value="">اختر من القائمة</option>
            <option value="micro">ميكرو باص</option>
            <option value="micro2">فان هيونداي</option>
          </select>

          <fieldset>
            <legend>Size</legend>
            <label htmlFor="small">
              <input type="radio" id="small" name="size" value="small" defaultChecked />
              Small
            </label>
            <label htmlFor="medium">
              <input type="radio" id="medium" name="size" value="medium" />
              Medium
            </label>
            <label htmlFor="large">
              <input type="radio" id="large" name="size" value="large" />
              Large
            </label>
            <label htmlFor="extralarge">
              <input type="radio" id="extralarge" name="size" value="extralarge" disabled />
              Extra Large
            </label>
          </fieldset>

          <fieldset>
            <label htmlFor="terms">
              <input type="checkbox" id="terms" name="terms" />
               الموافقة على الشروط والاحكام.
            </label>

          </fieldset>

          <fieldset>
            <label htmlFor="switch">
              <input type="checkbox" id="switch" name="switch" role="switch" />
              تفضيل الصعود من باب المنزل.
            </label>
          </fieldset>

          <button type="submit" className="secondary">ارسال</button>
          <button type="button" className="contrast">ارسال</button>
          <a href="#" role="button" >Contrast</a>


        </div>
      </section>
    </Layout>
  );
}

export default Reg
